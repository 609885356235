<template>
  <div class="course-page">
    <el-breadcrumb separator-class="el-icon-right">
      <el-breadcrumb-item :to="{ path: '/admin/dashboard' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/dashboard' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>内容管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/content/courses'}">教学内容管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/content/courses/course'}">课程管理</el-breadcrumb-item>
      <el-breadcrumb-item>编辑课程</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <el-form :model="pageForm" :rules="pageRules" ref="pageRef" label-width="auto" class="page-form">
        <el-form-item label="归属小分类" prop="tid_min_classify">
          <el-select v-model="pageForm.tid_min_classify" placeholder="请选择归属小分类">
            <el-option :label="item.title" :value="item.m_id" v-for="item in minClassifyList" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="课程名称" prop="title">
          <el-input v-model="pageForm.title"></el-input>
        </el-form-item>
        <el-form-item label="课程缩略图" prop="thumbnail">
          <upload :url="pageForm.thumbnail" :filed="'thumbnail'" :max-num="1" @checked-files="checkedFile" @del-select="delFile"></upload>
        </el-form-item>
        <el-form-item label="课程背景图" prop="background">
          <upload :url="pageForm.background" :filed="'background'" :max-num="1" @checked-files="checkedFile" @del-select="delFile"></upload>
        </el-form-item>
        <el-form-item label="课程内容(多图)" prop="content">
          <upload :urlArr="pageForm.content" :filed="'content'" :max-num="100" @checked-files="checkedFile" @del-select="delFile"></upload>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submit">立即更新</el-button>
          <el-button @click="back">返 回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Upload from '../../common/Upload'
export default {
  name: 'CourseEdit',
  components: {
    Upload
  },
  data () {
    const checkimg = (rule, value, callback) => {
      if (value !== '') {
        // 获取最后一个.的位置
        const index = value.lastIndexOf('.')
        // 获取后缀
        const ext = value.substr(index + 1)
        if (['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'psd', 'svg', 'tiff'].indexOf(ext.toLowerCase()) !== -1) {
          callback()
        } else {
          callback(new Error('文件类型不符'))
        }
      } else {
        callback()
      }
    }
    return {
      minClassifyList: [],
      pageForm: {
        m_id: this.$route.params.mid,
        tid_min_classify: '',
        title: '',
        thumbnail: '',
        background: '',
        content: []
      },
      pageRules: {
        tid_min_classify: [
          { required: true, message: '请选择归属小分类', trigger: 'blur' }
        ],
        title: [
          { required: true, message: '请填写课程名称', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        thumbnail: [
          { required: true, message: '请上传课程缩略图', trigger: 'blur' },
          { validator: checkimg, message: '文件类型不正确', trigger: 'blur' }
        ],
        background: [
          { required: true, message: '请上传课程背景图', trigger: 'blur' },
          { validator: checkimg, message: '文件类型不正确', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请上传课程内容(多图)', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getMinClassifyList()
  },
  methods: {
    // 获取专题列表
    async getMinClassifyList () {
      const { data: res } = await this.$http.get('/min-classify-list')
      if (res.code === 200) {
        this.minClassifyList = res.data
        await this.getItemInfo()
      } else {
        this.$message.error(res.msg)
      }
    },
    // 获取编辑内容
    async getItemInfo () {
      const { data: res } = await this.$http.get('/course', { params: { mid: this.$route.params.mid } })
      if (res.code === 200) {
        this.pageForm.tid_min_classify = res.data.tid_min_classify
        this.pageForm.title = res.data.title
        this.pageForm.thumbnail = res.data.thumbnail
        this.pageForm.background = res.data.background
        this.pageForm.content = res.data.content
      } else {
        this.$message.error(res.msg)
      }
    },
    submit () {
      this.$refs.pageRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.put('/course', this.pageForm)
          if (res.code === 200) {
            this.$message.success(res.msg)
            await this.$router.push('/admin/content/courses/course')
          } else {
            this.$message.error(res.msg)
          }
        } else {
          this.$message.error('表单验证失败')
          return false
        }
      })
    },
    back () {
      this.$router.push('/admin/content/courses/course')
    },
    // 上传组件返回值
    checkedFile (data) {
      this.pageForm[data.filed] = data.url
    },
    // 删除已选择文件
    delFile (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.pageForm[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.pageForm[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.pageForm[field].splice(checkedIndex, 1)
      }
    }
  }
}
</script>

<style scoped>
.page-content{
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}
.page-form{
  width: 600px;
  margin-right: auto;
  margin-left: auto;
}
</style>
